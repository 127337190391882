.autocomplete-selected {
  background: #f5f5f5;
  color: #262626; }

.autocomplete-suggestion {
  cursor: pointer;
  overflow: hidden;
  padding: 3px 20px;
  white-space: nowrap; }

.autocomplete-suggestions {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow: auto;
  padding: 5px 0; }

.currency-input {
  text-align: right; }

text-display-widget {
  max-width: 100%; }

.text-display-widget-container {
  display: block !important; }

.text-display-widget-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  padding: 1px 5px; }

.text-display-widget-placeholder {
  opacity: 0.5; }
